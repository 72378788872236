import { useContext } from "react";
import { db } from "../config/firebase";
import { doc, updateDoc, deleteDoc, setDoc } from "firebase/firestore";
import {
	CurrentFlow,
	CourseCode,
	CourseSections,
	PinnedClasses,
	IsMobile,
} from "../helpers/Contexts";
import { useAuthState } from "react-firebase-hooks/auth";
import { fireBaseAuth } from "../config/firebase";

function removeParenthesesContent(title) {
	return title.replace(/ \([^)]*\)/, "");
}

function SectionEditing(props) {
	const { shareMode, restrictedDict, setRestrictedDict } = props;
	const { courseCode, setCourseCode } = useContext(CourseCode);
	const { courseSections, setCourseSections } = useContext(CourseSections);
	const { pinnedClasses, setPinnedClasses } = useContext(PinnedClasses);
	var { currentFlow, setCurrentFlow } = useContext(CurrentFlow);
	const [user, loading, error] = useAuthState(fireBaseAuth);
	const { isMobile, setIsMobile } = useContext(IsMobile);

	// console.log(courseSections)

	return (
		<div className="pl-2">
			{Object.keys(courseSections).map((courseCode, index) => {
				const data = pinnedClasses[courseCode];
				// check if there is only 1 courseSection and it is not available
				if (
					(courseSections[courseCode].length === 1 &&
						courseSections[courseCode][0].type ===
							"NOT_AVAILABLE") ||
					data === undefined
				) {
					// if the course is not available this quarter
					return (
						<div key={index}>
							<div className="flex w-fulls justify-left pb-1">
								{/* Remove button */}
								{!shareMode ? (
									<button
										type="button"
										className="pr-2 group"
										onClick={() => {
											// setPinnedClasses((prevState) => ({
											// 	...prevState,
											// 	[courseCode]: {
											// 		show: prevState[courseCode][
											// 			"show"
											// 		],
											// 		units: prevState[
											// 			courseCode
											// 		]["units"],
											// 		hours: prevState[
											// 			courseCode
											// 		]["medianHours"],
											// 		title: prevState[
											// 			courseCode
											// 		]["title"],
											// 		flow: prevState[courseCode][
											// 			"flow"
											// 		],
											// 		disabled: true, // Adjust this logic as needed
											// 	},
											// }));
											
											// delete key from pinned classes
											// setPinnedClasses((prevState) => {
											// 	const newState = {
											// 		...prevState,
											// 	};
											// 	delete newState[courseCode];
											// 	return newState;
											// });

											deleteDoc(
												doc(
													db,
													"Planned",
													user.uid,
													`${currentFlow[0]}-${currentFlow[1]}`,
													courseCode
												)
											);
										}}
										onMouseOver={(event) =>
											(event.currentTarget.style.transform =
												"scale(1)")
										}
										onMouseOut={(event) =>
											(event.currentTarget.style.transform =
												"scale(1)")
										}
									>
										<svg
											className="w-5 h-5 text-slate-500 group-hover:hidden"
											aria-hidden="true"
											xmlns="http://www.w3.org/2000/svg"
											fill="none"
											viewBox="0 0 20 20"
										>
											<path
												stroke="currentColor"
												strokeLinecap="round"
												strokeLinejoin="round"
												strokeWidth="2"
												d="m13 7-6 6m0-6 6 6m6-3a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
											/>
										</svg>
										<svg
											className="w-5 h-5 text-red-500 hidden group-hover:block"
											aria-hidden="true"
											xmlns="http://www.w3.org/2000/svg"
											fill="currentColor"
											viewBox="0 0 20 20"
										>
											<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 11.793a1 1 0 1 1-1.414 1.414L10 11.414l-2.293 2.293a1 1 0 0 1-1.414-1.414L8.586 10 6.293 7.707a1 1 0 0 1 1.414-1.414L10 8.586l2.293-2.293a1 1 0 0 1 1.414 1.414L11.414 10l2.293 2.293Z" />
										</svg>
									</button>
								) : null}

								{/* Course code and name */}
								<div
									className="pl-1 w-2/5 items-center self-center cursor-pointer text-left"
									onClick={() => {
										setCourseCode(courseCode);
									}}
								>
									<h1 className="font-bold text-lg">
										{courseCode}
									</h1>
									{/* {data.title !== undefined ? (
										<h3 className="text-sm text-neutral-700 dark:text-neutral-200 line-clamp-2">
											{removeParenthesesContent(
												data.title
											)}
										</h3>
									) : null} */}
								</div>

								{/* Section Times */}
								<div className="w-3/5 flex flex-row flex-wrap">
									<div className="w-full py-1 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300 text-right">
										Not available this quarter
									</div>
								</div>
							</div>

							{index !== Object.keys(courseSections).length - 1 ||
							restrictedDict === undefined ||
							restrictedDict.length === 0 ? (
								<hr className="px-10 h-px bg-gray-200 border-0 dark:bg-gray-700 mb-1 mx-auto" />
							) : null}
						</div>
					);
				}

				let showForCode = data["show"];

				return (data["currentUserHidden"] === undefined ||
					data["currentUserHidden"] === false) &&
					data.disabled !== true ? (
					<div key={index}>
						<div className="flex w-fulls justify-left pb-1">
							{/* Remove button */}
							{!shareMode ? (
								<button
									type="button"
									className="pr-2 group"
									onClick={() => {
										setPinnedClasses((prevState) => ({
											...prevState,
											[courseCode]: {
												show: prevState[courseCode][
													"show"
												],
												units: prevState[courseCode][
													"units"
												],
												hours: prevState[courseCode][
													"medianHours"
												],
												title: prevState[courseCode][
													"title"
												],
												flow: prevState[courseCode][
													"flow"
												],
												disabled: true, // Adjust this logic as needed
											},
										}));
										deleteDoc(
											doc(
												db,
												"Planned",
												user.uid,
												`${currentFlow[0]}-${currentFlow[1]}`,
												courseCode
											)
										);
									}}
									onMouseOver={(event) =>
										(event.currentTarget.style.transform =
											"scale(1)")
									}
									onMouseOut={(event) =>
										(event.currentTarget.style.transform =
											"scale(1)")
									}
								>
									<svg
										className="w-5 h-5 text-slate-500 group-hover:hidden"
										aria-hidden="true"
										xmlns="http://www.w3.org/2000/svg"
										fill="none"
										viewBox="0 0 20 20"
									>
										<path
											stroke="currentColor"
											strokeLinecap="round"
											strokeLinejoin="round"
											strokeWidth="2"
											d="m13 7-6 6m0-6 6 6m6-3a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
										/>
									</svg>
									<svg
										className="w-5 h-5 text-red-500 hidden group-hover:block"
										aria-hidden="true"
										xmlns="http://www.w3.org/2000/svg"
										fill="currentColor"
										viewBox="0 0 20 20"
									>
										<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 11.793a1 1 0 1 1-1.414 1.414L10 11.414l-2.293 2.293a1 1 0 0 1-1.414-1.414L8.586 10 6.293 7.707a1 1 0 0 1 1.414-1.414L10 8.586l2.293-2.293a1 1 0 0 1 1.414 1.414L11.414 10l2.293 2.293Z" />
									</svg>
								</button>
							) : null}

							{/* Course code and name */}
							<div
								className="pl-1 w-2/5 items-center self-center cursor-pointer text-left"
								onClick={() => {
									setCourseCode(courseCode);
								}}
							>
								<h1 className="font-bold text-lg">
									{courseCode}
								</h1>
								{data.title !== undefined ? (
									<h3 className="text-sm text-neutral-700 dark:text-neutral-200 line-clamp-2">
										{removeParenthesesContent(data.title)}
									</h3>
								) : null}
							</div>

							{/* Section Times */}
							<div className="w-3/5 flex flex-row flex-wrap">
								{courseSections[courseCode].map(
									(section, sectionIndex) => (
										<>
											<div
												className="flex items-center justify-center w-1/2 pl-6"
												key={sectionIndex}
											>
												<input
													id={`vue-checkbox-${index}-${sectionIndex}`}
													type="checkbox"
													value=""
													checked={
														showForCode[
															sectionIndex
														]
													}
													onChange={() => {
														if (!shareMode) {
															let newPinnedClasses =
																{
																	...pinnedClasses,
																};
															newPinnedClasses[
																courseCode
															]["show"][
																sectionIndex
															] =
																!newPinnedClasses[
																	courseCode
																]["show"][
																	sectionIndex
																];
															setPinnedClasses(
																newPinnedClasses
															);

															// upload to firebase
															const docRef = doc(
																db,
																"Planned",
																user.uid,
																`${currentFlow[0]}-${currentFlow[1]}`,
																courseCode
															);
															updateDoc(docRef, {
																show: newPinnedClasses[
																	courseCode
																]["show"],
															});
														}
													}}
													className="self-center w-5 h-5 text-blue-500 dark:text-blue-400 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
												/>
												{/* checked if pinnedClasses[index] is true */}
												<label
													for={`vue-checkbox-${index}-${sectionIndex}`}
													className="w-full text-left py-1 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
												>
													{section.sectionNumber}{" "}
													{section.description !== ""
														? "- " +
															section.description.split(
																" "
															)[0]
														: ""}
												</label>
											</div>
											<div
												className={
													"w-1/2 text-right self-center " +
													(isMobile ? "pr-2" : "")
												}
											>
												<div className="self-center text-sm font-medium text-gray-900 dark:text-gray-300">
													{section.description !== ""
														? section.description
																.split(" ")
																.slice(1)
																.join(" ")
																.slice(0, -1)
														: "No times available"}
												</div>
											</div>
										</>
									)
								)}
								{courseSections[courseCode].length === 0 ? (
									<div className="w-full py-1 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300 text-left">
										No sections available
									</div>
								) : null}
							</div>
						</div>

						{index !== Object.keys(courseSections).length - 1 ||
						restrictedDict === undefined ||
						restrictedDict.length === 0 ? (
							<hr className="px-10 h-px bg-gray-200 border-0 dark:bg-gray-700 mb-1 mx-auto" />
						) : null}
					</div>
				) : null;
			})}

			{/* RESTRICTED */}
			{restrictedDict === undefined ||
			Object.keys(restrictedDict).length === 0 ? null : (
				<hr className="px-10 top-5 h-px border-2 border-yellow-400 mb-1 mx-auto rounded-md" />
			)}
			{shareMode
				? null
				: Object.keys(restrictedDict).map((key, index) => {
						const data = restrictedDict[key];

						return (
							<div key={key}>
								<div className="flex w-fulls justify-left pb-1">
									{/* Remove button */}
									<button
										type="button"
										className="pr-2 group"
										onClick={() => {
											const newRestrictedTime = {
												...restrictedDict,
											};
											delete newRestrictedTime[key];

											setRestrictedDict(
												newRestrictedTime
											);

											setCourseSections((prevState) => {
												const newState = {
													...prevState,
												};
												delete newState[key];
												return newState;
											});

											// upload new one to firebase
											const restrictedRef = doc(
												db,
												"Planned",
												user.uid,
												`${currentFlow[0]}-${currentFlow[1]}`,
												"RESTRICTED"
											);
											setDoc(restrictedRef, {
												items: newRestrictedTime,
											});
										}}
										onMouseOver={(event) =>
											(event.currentTarget.style.transform =
												"scale(1)")
										}
										onMouseOut={(event) =>
											(event.currentTarget.style.transform =
												"scale(1)")
										}
									>
										<svg
											className="w-5 h-5 text-slate-500 group-hover:hidden"
											aria-hidden="true"
											xmlns="http://www.w3.org/2000/svg"
											fill="none"
											viewBox="0 0 20 20"
										>
											<path
												stroke="currentColor"
												strokeLinecap="round"
												strokeLinejoin="round"
												strokeWidth="2"
												d="m13 7-6 6m0-6 6 6m6-3a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
											/>
										</svg>
										<svg
											className="w-5 h-5 text-red-500 hidden group-hover:block"
											aria-hidden="true"
											xmlns="http://www.w3.org/2000/svg"
											fill="currentColor"
											viewBox="0 0 20 20"
										>
											<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 11.793a1 1 0 1 1-1.414 1.414L10 11.414l-2.293 2.293a1 1 0 0 1-1.414-1.414L8.586 10 6.293 7.707a1 1 0 0 1 1.414-1.414L10 8.586l2.293-2.293a1 1 0 0 1 1.414 1.414L11.414 10l2.293 2.293Z" />
										</svg>
									</button>
									{/* Course code and name */}
									<div
										className="pl-1 w-2/5 items-center self-center cursor-pointer text-left"
										onClick={() => {
											// setCourseCode(courseCode);
										}}
									>
										<h1 className="font-bold text-lg text-yellow-400 dark:text-yellow-400">
											{key}
										</h1>
										{data.title !== undefined ? (
											<h3 className="text-sm text-neutral-700 dark:text-neutral-200 line-clamp-2">
												{removeParenthesesContent(
													data.title
												)}
											</h3>
										) : null}
									</div>

									{/* Section Times */}
									<div className="w-3/5 flex flex-row flex-wrap">
										{courseSections[key] === undefined
											? null
											: courseSections[key].map(
													(section, sectionIndex) => (
														<>
															<div
																className="flex items-center justify-center w-1/2 pl-6"
																key={
																	sectionIndex
																}
															>
																{/* checked if pinnedClasses[index] is true */}
																<label
																	for={`vue-checkbox-${index}-${sectionIndex}`}
																	className="w-full text-left py-1 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
																>
																	{
																		section.sectionNumber
																	}{" "}
																	{section.description !==
																	""
																		? "- " +
																			section.description.split(
																				" "
																			)[0]
																		: ""}
																</label>
															</div>
															<div
																className={
																	"w-1/2 text-right self-center " +
																	(isMobile
																		? "pr-2"
																		: "")
																}
															>
																<div className="self-center text-sm font-medium text-gray-900 dark:text-gray-300">
																	{section.description !==
																	""
																		? section.description
																				.split(
																					" "
																				)
																				.slice(
																					1
																				)
																				.join(
																					" "
																				)
																				.slice(
																					0,
																					-1
																				)
																		: "No times available"}
																</div>
															</div>
														</>
													)
												)}
									</div>
								</div>
								<hr className="px-10 h-px bg-gray-200 border-0 dark:bg-gray-700 mb-1 mx-auto" />
							</div>
						);
					})}
		</div>
	);
}

export default SectionEditing;
